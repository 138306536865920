import TemplateBlog from "../template-blog";
import MainArticulo4 from "./main/main-articulo4";

export default function Articulo4(){
  return(
    <TemplateBlog
      descrip={"Seguridad y Salud Laboral en Condominios y Edificios"}
      Main={MainArticulo4}
    />
  )
}