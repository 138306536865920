import TemplateBlog from "../template-blog";
import MainArticulo2 from "./main/main-articulo2";

export default function Articulo2(){
  return(
    <TemplateBlog
      descrip={"Partes interesadas o Stakeholders en normas ISO"}
      Main={MainArticulo2}
    />
  )
}