import TemplateBlog from "../template-blog";
import MainArticulo6 from "./main/main-articulo6";

export default function Articulo6(){
  return(
    <TemplateBlog
      descrip={"¿Cómo proceder ante una inspección de SUNAFIL?"}
      Main={MainArticulo6}
    />
  )
}