import TemplateBlog from "../template-blog";
import MainArticulo9 from "./main/main-articulo9";

export default function Articulo9(){
  return(
    <TemplateBlog
      descrip={"Seguridad y Salud en el Teletrabajo"}
      Main={MainArticulo9}
    />
  )
}