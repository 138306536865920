import TemplateBlog from "../template-blog";
import MainArticulo8 from "./main/main-articulo8";

export default function Articulo8(){
  return(
    <TemplateBlog
      descrip={"¿Cómo implemento ISO-14001 en mi organización?"}
      Main={MainArticulo8}
    />
  )
}